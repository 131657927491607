import { useEffect } from 'react'

const useChatApp = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.defer = true
    script.src =
      'https://desk.zoho.com/portal/api/web/inapp/493246000000572017?orgId=713120655'

    // Append the script to the document's head
    document.head.appendChild(script)

    // Clean up the script when the component unmounts
    return () => {
      document.head.removeChild(script)
    }
  }, [])

  return null
}

export default useChatApp
