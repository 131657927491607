import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lineItemsLength: 0,
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setLineItems: (state, action) => {
      state.lineItemsLength = action.payload
    },

    resetCart: (state) => {
      state.lineItemsLength = 0
    },
  },
})

export const { setLineItems, resetCart } = cartSlice.actions

export default cartSlice.reducer
