import axios from 'axios'
import store from '../redux/store'
import { navigate } from 'gatsby'
import { logout } from '../features/userSlice'
import { loginURL } from '../ApiEndpoints'

class Api {
  constructor() {
    this.instance = axios.create({
      baseURL: process.env.GATSBY_API_BASEURL,
    })

    this.instance.interceptors.request.use((req) => {
      // Include token validation here.
      const user = store.getState()?.['auth']?.['user']
      const _token = user?.['accessToken']

      if (_token) {
        req.headers['X-Auth-Token'] = `${_token}`
      }

      if (process.env.GATSBY_HTTP_ORIGIN) {
        req.headers['HTTP_ORIGIN'] = `${process.env.GATSBY_HTTP_ORIGIN}`
      }

      return req
    })

    let isLogoutDispatched = false // Add a flag to track whether the logout action has been dispatched

    this.instance.interceptors.response.use(
      function (res) {
        return res
      },
      function (error) {
        if (error.response.status === 401 && !isLogoutDispatched) {
          isLogoutDispatched = true
          localStorage.removeItem('persist:root')
          // use store.dispatch() instead of just dispatch()
          store.dispatch(logout())
          if (!error.response.config.url.includes(loginURL)) {
            navigate('/login')
          }
        }
        return error
      }
    )
  }

  get(endpoint, params = {}, config = {}) {
    return this.instance
      .get(endpoint, {
        ...config,
        params,
      })
      .catch((error) => {
        console.error(error, config)
        return error.response
      })
  }

  delete(endpoint, data = {}, config) {
    return this.instance
      .delete(endpoint, { ...config, data })
      .catch((error) => {
        console.error(error, config)
        return error.response
      })
  }

  post(endpoint, data = {}, config) {
    return this.instance.post(endpoint, data, config).catch((error) => {
      console.error(error, config)
      return error.response
    })
  }

  put(endpoint, data = {}, config) {
    return this.instance.put(endpoint, data, config).catch((error) => {
      console.error(error, config)
      return error.response
    })
  }
}

const API = new Api()

export default API
