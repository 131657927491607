import { adminUserLoginURL, loginURL, logoutURL } from '../ApiEndpoints'
import Api from '../services/Api'
import cleanup from '../utils/cleanup'

// midwest customer login
const midwestCustomerLogin = async (et) => {
  const loginToken = {
    bc_token: et,
  }
  const response = await Api.post(loginURL, loginToken)
  return response.data
}

// customer login api call
const adminCustomerLogin = async (token) => {
  const loginToken = {
    token: token,
  }
  const response = await Api.post(adminUserLoginURL, loginToken)
  return response.data
}

// login api call
const login = async (values) => {
  const loginData = {
    username: values.email,
    password: values.password,
  }
  const response = await Api.post(loginURL, loginData)
  return response?.data ? response.data : response.response
}

// logout api call
const logout = async () => {
  const response = await Api.post(logoutURL)

  // cleanup code ...
  // reset session storage ...
  if (response.status === 200) {
    cleanup()
  }

  return response.data
}

const authService = { login, logout, adminCustomerLogin, midwestCustomerLogin }

export default authService
