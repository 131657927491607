import { combineReducers, configureStore } from '@reduxjs/toolkit'
import counterReducer from './counter'
import authReducer from '../features/userSlice'
import { persistReducer, persistStore } from 'redux-persist'
import createWebStorage from 'redux-persist/lib/storage/createWebStorage'
import cartReducer from '../features/cartSlice'
import customerReducer from '../features/customerSlice'

// Define a "no-op" storage object that doesn't persist anything
const createNoopStorage = () => ({
  getItem: (_key) => Promise.resolve(null),
  setItem: (_key, value) => Promise.resolve(value),
  removeItem: (_key) => Promise.resolve(),
})

// Use local storage if it's available in the browser, otherwise use the "no-op" storage
const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage()

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  product_list: counterReducer,
  auth: authReducer,
  cart: cartReducer,
  customer: customerReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
})

// Create a persistor that allows for rehydration of the persisted state
export const persistor = persistStore(store)

export default store
