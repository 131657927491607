import { removePOFromSessionStorage } from "./purchaseOrder";

const cleanup = () => {
  // [1] remove session storage product quick-view popup data ...
  sessionStorage.removeItem("visitorData");

  // [2] remove checkout-iframe from ___gatsby DOM element ...
  const checkoutIframe = document.querySelector("#checkout-iframe");

  if (checkoutIframe) {
    checkoutIframe.parentNode.removeChild(checkoutIframe);
  }

  // [3] remove PO items from localStorage data ...
  removePOFromSessionStorage();
  
  localStorage.removeItem("recentlyViewProductIds");

  localStorage.removeItem("recentlyViewProductIdsBeforeLogin");
  

  return null;
};

export default cleanup;
