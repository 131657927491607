import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authServices'
import { navigate } from 'gatsby'
import { adminUserLoginURL, loginURL, logoutURL } from '../ApiEndpoints'

const initialState = {
  isSuccess: false,
  isError: false,
  isLoading: false,
  user: null,
  message: '',
}

// customer Login from admin panel...
export const midwestCustomerLogin = createAsyncThunk(
  adminUserLoginURL,
  async ({ et, toastMessage }, thunkAPI) => {
    try {
      const res = await authService.midwestCustomerLogin(et)
      if (res.status === 200) {
        toastMessage('success', 'Logged in successully.')
        navigate('/')
      }
      // when user is not authenticated...
      if (!res) {
        toastMessage('error', 'login failed.')
      }
      return res
    } catch (error) {
      console.error(error)
    }
  }
)

// customer Login from admin panel...
export const adminLogin = createAsyncThunk(
  adminUserLoginURL,
  async ({ token, toastMessage }, thunkAPI) => {
    try {
      const res = await authService.adminCustomerLogin(token)
      if (res.status === 200) {
        toastMessage('success', 'Logged in successully.')
        navigate('/cart')
      }
      // when user is not authenticated...
      if (!res) {
        toastMessage('error', 'login failed.')
      }
      return res
    } catch (error) {
      console.error(error)
    }
  }
)

// customer login...
export const login = createAsyncThunk(
  loginURL,
  async ({ values: user, toastMessage }, thunkAPI) => {
    try {
      const res = await authService.login(user)
      // when user is not authenticated...
      if (res?.status === 401) {
        toastMessage(
          'error',
          'Verify your user email and password and try again.'
        )
      }
      if (res.status === 200) {
        toastMessage('success', 'Logged in successully.')
      }

      return res
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// customer logout...
export const logout = createAsyncThunk(logoutURL, async (thunkAPI) => {
  try {
    return await authService.logout()
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = null
      })
      .addCase(adminLogin.pending, (state) => {
        state.isLoading = true
      })
      .addCase(adminLogin.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(adminLogin.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
  },
})

export const { reset } = authSlice.actions
export default authSlice.reducer
