import Cookies from "universal-cookie";

const cookies = new Cookies();
const compare_cookie = "compare_list";

export function isProductAvailable(availability) {
  if (availability === "Available" || availability === "available") {
    return true;
  } else {
    return false;
  }
}

export const setCookie = (product_ids) => {
  cookies.set(compare_cookie, product_ids, { path: "/" });
};

export const setCompareCookie = (product_id, toastMessage) => {
  let res = cookies.get(compare_cookie);

  // When Cookie already exist and it has some value ...
  if (res !== "" && res !== " " && res !== undefined) {
    let productIds = res.split(" ");

    // When user click on same product that already in compare list ...
    if (productIds.includes(product_id.toString())) {
      // Remove id from the cookies ...
      productIds = productIds.filter((item) => item !== product_id.toString());
      toastMessage("warning", "item removed from the compare");
    } else {
      // Apend id from the cookies ...
      productIds.push(`${product_id}`);
      toastMessage("success", "item added for the compare");
    }

    setCookie(productIds.join(" "));
  }

  // Create new Cookie when it does not exist ...
  else {
    setCookie(product_id);
    toastMessage("success", "item added for the compare");
  }
};

export function getCompareProducts() {
  const res = cookies.get(compare_cookie);
  return res ? res.split(" ") : [];
}

export function dataToPricing(data) {
  const prices = {};

  data.forEach((i) => {
    prices[i["entityId"]] = {
      ...(i?.["prices"]?.["price"] && {
        price: i["prices"]["price"]["value"],
      }),
      ...(i?.["prices"]?.["salePrice"] && {
        salePrice: i["prices"]["salePrice"]["value"],
      }),
      ...(i?.["prices"]?.["retailPrice"] && {
        retailPrice: i["prices"]["retailPrice"]["value"],
      }),
    };
  });

  return prices;
}

const placeholder = "/img/default-bc-product.png";

export function dataToImages(images) {
  const defaultArray = [
    {
      id: 0,
      is_default: true,
      sort_order: 0,
      url_standard: placeholder,
      url_thumbnail: placeholder,
      url_zoom: placeholder,
    },
  ];

  if (images?.length) {
    const mappedImages = images.map((i, index) => {
      return {
        id: i?.["id"] || index,
        is_default: i?.["is_default"],
        sort_order: i?.["sort_order"],
        url_standard: i?.["url_standard"] || i?.["url"] || placeholder,
        url_thumbnail: i?.["url_thumbnail"] || i?.["url"] || placeholder,
        url_zoom: i?.["url_zoom"] || i?.["url"] || placeholder,
      };
    });
    const sortedImages = mappedImages.sort(
      (a, b) => a.sort_order - b.sort_order
    );
    return sortedImages;
  } else {
    return defaultArray;
  }
}

export const dataToProductQuickViewDetails = (data) => {
  const productDetails = {
    name: data?.name || "",
    description: data?.description || "",
    brandName: data?.brand_name || "",
    brandUrl: data?.brand_url || "",
    basePrice: data?.price || 0,
    retailPrice: data?.retail_price || 0,
    salePrice: data?.sale_price || 0,
    availability: data?.availability || "",
    sku: data?.sku || "",
    images: [],
    upc: data?.upc || "",
    orderQuantityMinimum: data?.order_quantity_minimum || 0,
    orderQuantityMaximum: data?.order_quantity_maximum || 0,
    weight: data?.weight || {
      unit: "",
      value: 0,
    },
    reviewCount: data?.review_count || 0,
    reviewRatingSum: data?.review_rating_sum || 0,
    bulkPricingRules: data?.bulk_pricing_rules || [],
  };

  if (data?.custom_fields?.length) {
    productDetails["customFields"] = data["custom_fields"];
  }

  if (data?.images?.length) {
    productDetails["images"] = data["images"];
  }

  return productDetails;
};

export const dataToPDPBrandDetails = (brand) => {
  return {
    brandName: brand?.["name"] || "",
    brandUrl: brand?.["custom_url"]?.["url"] || "",
  };
};

export const dataToGQLProductDetails = (gqlData) => {
  const graphQL = gqlData[0];
  const pDetails = {
    productId: graphQL["bigcommerce_id"],
    name: graphQL["name"],
    productUrl: graphQL["custom_url"]["url"] || "",
    sku: graphQL["sku"] || "",
    upc: graphQL["upc"] || "",
    orderQuantityMinimum: graphQL["order_quantity_minimum"] || 0,
    orderQuantityMaximum: graphQL["order_quantity_maximum"] || 0,
    weight: graphQL["weight"] || null,
    description: graphQL["description"] || null,
    warranty: graphQL["warranty"] || "",
    reviewCount: graphQL["review_count"] || 0,
    reviewRatingSum: graphQL["review_rating_sum"] || 0,
    availability: graphQL["availability"] || {},
    productCategoryIds: graphQL["categories"] || [],
    images: graphQL["images"] || [],
    videos: graphQL["videos"] || [],
    customFields: graphQL["custom_fields"] || [],
  };

  return pDetails;
};

export const dataToProductDynamicDetails = (product) => {
  return {
    basePrice: product?.["price"] || 0,
    retailPrice: product?.["retail_price"] || 0,
    salePrice: product["sale_price"] || 0,
    bulkPricingRules: product?.["bulk_pricing_rules"] || [],
    relatedProducts: product?.["related_products"] || [],
    isVisible: product?.["is_visible"],
    components: product?.["active_version"]?.["components"] || [],
  };
};

export function updateRecentlyViewedProducts(
  productId,
  maxItems = 25,
  currentLoginUser
) {
  let recentlyViewed = localStorage.getItem("recentlyViewProductIds");

  if (!recentlyViewed || recentlyViewed === "undefined") {
    recentlyViewed = [];
  } else {
    recentlyViewed = JSON.parse(recentlyViewed);
  }

  if (!Array.isArray(productId)) {
    const existingIndex = recentlyViewed.indexOf(productId);

    // If the ID is already in the list, remove it
    if (existingIndex !== -1) {
      recentlyViewed.splice(existingIndex, 1);
    }

    // Add the new ID to the end of the list
    recentlyViewed.push(productId);
  }

  // Ensure the list doesn't exceed the maximum allowed items
  if (recentlyViewed.length > maxItems) {
    recentlyViewed.shift(); // Remove the first item
  }

  // Save the updated list back to localStorage
  localStorage.setItem(
    "recentlyViewProductIds",
    JSON.stringify(recentlyViewed)
  );

  if (currentLoginUser === false && !currentLoginUser?.id) {
    localStorage.setItem(
      "recentlyViewProductIdsBeforeLogin",
      JSON.stringify(recentlyViewed)
    );
  }
}