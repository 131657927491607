export const updatePOLINEItem = (key, product, item) => {
  // get existing items from sessionStorage
  const storageArray = JSON.parse(sessionStorage.getItem(key)) || [];

  // find the index of the item with the same bop_id
  const existingItemIndex = storageArray.findIndex(
    (row) => row.bop_id === product.bop_id
  );

  // if the item already exists...
  if (existingItemIndex !== -1) {
    const existingProduct = storageArray[existingItemIndex];
    // find the index of the variant with the same variant_id
    const existingVariantIndex = existingProduct.variants.findIndex(
      (row) => row.variant_id === item.variant_id
    );

    // if the variant exists...
    if (existingVariantIndex !== -1) {
      // update the quantity of the variant
      if (item.requested_qty === 0 || item.requested_qty === "") {
        // remove the variant
        existingProduct.variants.splice(existingVariantIndex, 1);
      } else {
        existingProduct.variants[existingVariantIndex].requested_qty =
          item.requested_qty;
      }
    } else {
      // add the new variant
      existingProduct.variants.push({
        ...item,
        requested_qty: item.requested_qty,
      });
    }

    // if the product has no variants left, remove the product
    if (existingProduct.variants.length === 0) {
      storageArray.splice(existingItemIndex, 1);
    } else {
      // otherwise, update the product entry
      storageArray[existingItemIndex] = existingProduct;
    }
  } else {
    // if the item does not exist, add the item with the variant
    const newRow = {
      ...product,
      variants: [
        {
          ...item,
          requested_qty: item.requested_qty,
        },
      ],
    };
    storageArray.push(newRow);
  }

  // save the updated array to sessionStorage
  sessionStorage.setItem(key, JSON.stringify(storageArray));

  return storageArray;
};


export const removePOLineItem = (key, product, item) => {
  // get existing items from sessionStorage
  const storageArray = JSON.parse(sessionStorage.getItem(key)) || [];

  // find the index of the item with the same bop_id
  const existingItemIndex = storageArray.findIndex(
    (row) => row.bop_id === product.bop_id
  );

  // if the item exists...
  if (existingItemIndex !== -1) {
    // find the index of the variant with the same bc_sku
    const existingVariantIndex = storageArray[
      existingItemIndex
    ].variants.findIndex((row) => row.bc_sku === item.bc_sku);

    // if the variant exists...
    if (existingVariantIndex !== -1) {
      // if there is only one variant remove the entire product
      if (storageArray[existingItemIndex].variants.length === 1) {
        storageArray.splice(existingItemIndex, 1);
      } else {
        // remove the variant
        storageArray[existingItemIndex].variants.splice(
          existingVariantIndex,
          1
        );
      }
    }
  }

  // save the updated array to sessionStorage
  sessionStorage.setItem(key, JSON.stringify(storageArray));

  return storageArray;
};

export const getPOItems = (key) => {
  return JSON.parse(sessionStorage.getItem(key)) || [];
};

export const removePOFromSessionStorage = () => {
  const KEY_BO = "bulkorder";
  const KEY_PO = "preorder";

  sessionStorage.removeItem(KEY_BO);
  sessionStorage.removeItem(KEY_PO);
};

export const dataTOBrandsOptions = (data) => {
  if (!data) return [];

  return data.map((row) => ({
    value: row.id,
    label: row.name,
  }));
};

export const isPODisable = (key) => {
  const orders = getPOItems(key);

  if (!orders) return true;

  return false;
};

const processLineItems = (orders) => {
  const lineItems = [];

  orders.map((o) => {
    lineItems.push({
      bop_id: o.bop_id,
      product_name: o.name,
      total: o.variants.reduce(
        (total, row) => total + parseInt(row.requested_qty || 0),
        0
      ),
      variants: o.variants,
    });
  });

  return lineItems;
};

export const createPayload = (representative, type, user) => {
  const orders = getPOItems(type);
  const { customer_id, customer_name, customer_email, admin_name } = user;

  const payload = {
    customer_id: String(customer_id) || "",
    customer_name: customer_name || "",
    customer_rep_id: null,
    customer_rep_name: representative?.name || "",
    customer_email: customer_email || "",
    customer_rep_email: representative?.email || "",
    created_by: admin_name ? admin_name : customer_name,
    type: type,
    line_items: processLineItems(orders),
  };

  return payload;
};

export const mergeLocalDataToApiData = (key, apiData) => {
  const orders = getPOItems(key);

  if (!orders || !orders.length) {
    // replace requested_qty with value NaN
    apiData.map((o) => {
      o.variants = o.variants.map((v) => {
        return {
          ...v,
          requested_qty: NaN,
        };
      });
    });

    return apiData;
  }

  apiData.map((o) => {
    orders.map((order) => {
      if (o.bop_id === order.bop_id) {
        o.variants = o.variants.map((v) => {
          const existingVariant = order.variants.find(
            (row) => row.variant_id === v.variant_id
          );

          if (existingVariant) {
            return {
              ...v,
              requested_qty: existingVariant.requested_qty,
            };
          } else {
            return {
              ...v,
              requested_qty: NaN,
            };
          }
        });
      }
    });
  });

  return apiData;
};
