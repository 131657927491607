import { createSlice } from '@reduxjs/toolkit'
import { getCompareProducts } from '../utils/product'

const count = getCompareProducts()

export const counterSlice = createSlice({
  name: 'product_list',
  initialState: {
    products: count[0] !== '' ? count.length : 0,
  },
  reducers: {
    increment: (state) => {
      state.products =
        getCompareProducts().length === 1 && getCompareProducts()[0] === ''
          ? 0
          : getCompareProducts().length
    },
    decrement: (state) => {
      state.products =
        getCompareProducts().length !== 1 ? getCompareProducts().length : 1
    },
  },
})

// Action creators are generated for each case reducer function ...
export const { increment, decrement } = counterSlice.actions

export default counterSlice.reducer
