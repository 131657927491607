import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentLoginUser: null,
}

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentLoginUser = action.payload
    },
    resetCustomer: (state) => {
      state.currentLoginUser = null
    },
  },
})

export const { resetCustomer, setCurrentUser } = customerSlice.actions

export default customerSlice.reducer
